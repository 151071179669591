import Agent from "../../../Interfaces/agent";
import { FieldTypes } from "../../../utils/fieldTypes";

const getCommissionModelCopy = (commissionModel: string | null) => {
  if (!commissionModel) {
    return `For a home in your price range, Clever advertises ${commissionModel}. Is this the commission structure your agent quoted you in the meeting?`;
  }
  if (commissionModel === "Flat Fee Model") {
    return `For a home in your price range, Clever advertises a $3,000 listing commission (plus a typical buyer's agent commission of around 3%.) Is this the commission structure your agent quoted you in the meeting?`;
  }
  if (commissionModel === "1% Model") {
    return `For a home in your price range, Clever advertises a 1% listing commission (plus a typical buyer's agent commission of around 3%.) Is this the commission structure your agent quoted you in the meeting?`;
  }
  if (commissionModel === "1.5% Model") {
    return `For a home in your price range, Clever advertises a 1.5% listing commission, minimum $3,000 (plus a typical buyer's agent commission of around 3%.) Is this the commission structure your agent quoted you in the meeting?`;
  }
  if (
    commissionModel === "Under 125k Model" ||
    commissionModel === "Under 75k Model"
  ) {
    return `For a home in your price range, Clever advertises a 5% total commission. (This includes both the listing commission and the buyer's agent commission.) Is this the commission structure your agent quoted you in the meeting?`;
  }
  return `For a home in your price range, Clever advertises ${commissionModel}. Is this the commission structure your agent quoted you in the meeting?`;
};

const RecentlyMatchedAgentDefaultFields = (
  agent: Agent,
  index: number,
  commission_model: string
) => {
  const fields = [
    {
      type: FieldTypes.header,
      keyName: `agentHeader${index}`,
      label: `${agent.firstname} ${agent.lastname} ${
        agent.brokerage_name ? `of ${agent.brokerage_name}` : ""
      }`,
    },
    {
      label: `Have you met with ${agent.firstname} ${agent.lastname}?`,
      keyName: `have_you_met_with_agent_${index + 1}_`,
      type: FieldTypes.radioText,
      options: ["Yes", "No"],
    },
    {
      label: `Do you plan to meet with ${agent.firstname} ${agent.lastname}?`,
      keyName: `do_you_plan_to_meet_with_agent_${index + 1}___recently_met_`,
      type: FieldTypes.radioText,
      options: ["Yes", "No"],
      triggers: {
        [`have_you_met_with_agent_${index + 1}_`]: "No",
      },
    },
    {
      label: `Why have you decided against meeting with ${agent.firstname} ${agent.lastname}?`,
      keyName: `why_have_you_decided_against_meeting_with_agent_${
        index + 1
      }___recently_met_`,
      type: FieldTypes.textarea,
      triggers: {
        [`do_you_plan_to_meet_with_agent_${index + 1}___recently_met_`]: "No",
      },
    },
    {
      label: `Did ${agent.firstname} ${agent.lastname} present Clever in a positive manner?`,
      keyName: `did_agent_${index + 1}_present_clever_in_a_positive_manner_`,
      type: FieldTypes.radioNumber,
      options: ["1", "2", "3", "4", "5"],
      triggers: {
        [`have_you_met_with_agent_${index + 1}_`]: "Yes",
      },
    },
    {
      label: `On a scale of 1-5 do you feel ${agent.firstname} ${agent.lastname} is fully committed to helping you buy / sell your home?`,
      keyName: `do_you_feel_agent_${
        index + 1
      }_is_fully_committed_to_helping_you_buy___sell_your_home_`,
      type: FieldTypes.radioNumber,
      options: ["1", "2", "3", "4", "5"],
      triggers: {
        [`have_you_met_with_agent_${index + 1}_`]: "Yes",
      },
    },
    {
      label: `On a scale of 1-5 how would you rate ${agent.firstname} ${agent.lastname}'s knowledge of your local market?`,
      keyName: `how_would_you_rate_agent_${
        index + 1
      }_s_knowledge_of_your_local_market_`,
      type: FieldTypes.radioNumber,
      options: ["1", "2", "3", "4", "5"],
      triggers: {
        [`have_you_met_with_agent_${index + 1}_`]: "Yes",
      },
    },
    {
      label: `On a scale of 1-5 how strong of a match was ${agent.firstname} ${agent.lastname} based on what you were looking for in an agent?`,
      keyName: `how_strong_of_a_match_was_agent_${
        index + 1
      }_based_on_what_you_were_looking_for_in_an_agent_`,
      type: FieldTypes.radioNumber,
      options: ["1", "2", "3", "4", "5"],
      triggers: {
        [`have_you_met_with_agent_${index + 1}_`]: "Yes",
      },
    },
    {
      label: getCommissionModelCopy(commission_model),
      keyName: `did_agent_${index + 1}_pitch_the_correct_model_`,
      type: FieldTypes.radioText,
      options: ["Yes", "No"],
      triggers: {
        [`have_you_met_with_agent_${index + 1}_`]: "Yes",
      },
    },
    {
      label: `What commission structure did your agent present?`,
      keyName: `what_commission_structure_did_agent_${index + 1}_present_`,
      type: FieldTypes.textarea,
      triggers: {
        [`did_agent_${index + 1}_pitch_the_correct_model_`]: "No",
      },
    },
    {
      label: `Do you agree with the ${agent.firstname} ${agent.lastname}'s assessment of your home?`,
      keyName: `do_you_agree_with_the_agent_${
        index + 1
      }_s__assessment_of_your_home_`,
      type: FieldTypes.radioNumber,
      options: ["1", "2", "3", "4", "5"],
      triggers: {
        [`have_you_met_with_agent_${index + 1}_`]: "Yes",
      },
    },
    {
      label: `At this time, have you signed a listing agreement with ${agent.firstname} ${agent.lastname}?`,
      keyName: `have_you_signed_a_listing_agreement_with_agent_${index + 1}_`,
      type: FieldTypes.radioText,
      options: ["Yes", "No", "Not yet, but I plan to"],
      triggers: {
        [`have_you_met_with_agent_${index + 1}_`]: "Yes",
      },
    },
    {
      label: `How likely are you to use ${agent.firstname} ${agent.lastname} as your listing agent?`,
      keyName: `how_likely_are_you_to_use_agent_${
        index + 1
      }_as_your_listing_agent_`,
      type: FieldTypes.radioNumber,
      options: ["1", "2", "3", "4", "5"],
      triggers: {
        [`have_you_signed_a_listing_agreement_with_agent_${index + 1}_`]: "No",
      },
    },
  ];

  return fields;
};

const RecentlyMatchedGeneralFields = () => [
  {
    type: FieldTypes.header,
    keyName: "generalHeader",
    label: `General Questions`,
  },
  {
    label: "In general, how satisfied are you with the Clever process so far?",
    keyName: "how_satisfied_are_you_with_the_clever_process_so_far_",
    type: FieldTypes.radioNumber,
    options: ["1", "2", "3", "4", "5"],
  },
  {
    label: "Would you like us to get you an additional agent match?",
    keyName:
      "would_you_like_us_to_get_you_an_additional_agent_match___recently_met_",
    type: FieldTypes.radioText,
    options: ["Yes", "No"],
    triggers: {
      how_satisfied_are_you_with_the_clever_process_so_far_: ["1", "2", "3"],
    },
  },
  {
    label: "What qualities would you like to see in your next agent match?",
    keyName:
      "what_qualities_would_you_like_to_see_in_your_next_agent_match___recently_met_",
    type: FieldTypes.textarea,
    triggers: {
      would_you_like_us_to_get_you_an_additional_agent_match___recently_met_:
        "Yes",
    },
  },
  {
    label:
      "Based on your experience so far, how likely are you to refer a friend to Clever?",
    keyName: "how_likely_are_you_to_refer_a_friend_to_clever___recently_met_",
    type: FieldTypes.radioNumber,
    required: true,
    options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
  },
];

const getFields = (agents: Agent[], commission_model: string) => {
  let fields = [] as any[];
  const agentFields = agents
    .slice(0, 2)
    .map((agent, index) =>
      RecentlyMatchedAgentDefaultFields(agent, index, commission_model)
    )
    .flat();
  const generalFields = RecentlyMatchedGeneralFields();
  fields = [agentFields, generalFields].flat();
  return fields;
};

export { getFields };
