import React from "react";
import AuthenticationLayout from "../components/AuthenticationLayout";
import MetRecentAppointmentContainer from "../components/FormBuilder/MetRecentAppointment/MetRecentAppointmentContainer";
import "../styles/form.scss";

const RecentlyMet = () => (
  <AuthenticationLayout>
    <MetRecentAppointmentContainer />
  </AuthenticationLayout>
);

export default RecentlyMet;
